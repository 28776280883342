/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const brandColor = '#FF7700';
const iconDir = '/img/icons';

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl: url
                defaultImage: image
                twitterUsername
            }
        }
    }
`;

const SEO = ({ title, description, image, pathname, type, tags, children }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    defaultDescription,
                    siteUrl,
                    defaultImage,
                    twitterUsername
                }
            }
        }) => {
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: `${siteUrl}${image || defaultImage}`,
                url: `${siteUrl}${pathname || '/'}`
            };

            return (
                <Helmet
                    title={seo.title}
                    {...(seo.title !== defaultTitle
                        ? { titleTemplate: `%s 	▪ ${defaultTitle}` }
                        : {})}
                    htmlAttributes={{ lang: 'en' }}
                >
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta name="description" content={seo.description} />
                    {tags && tags.length && (
                        <meta name="keywords" content={tags.join(', ')} />
                    )}
                    <meta name="image" content={seo.image} />
                    {/* PWA configs */}
                    <meta name="application-name" content={defaultTitle} />
                    <meta
                        name="apple-mobile-web-app-title"
                        content={defaultTitle}
                    />
                    <meta name="theme-color" content={brandColor} />
                    <meta
                        name="msapplication-config"
                        content="/browserconfig.xml"
                    />
                    <link rel="manifest" href="/site.webmanifest" />
                    {/* Open Graph */}
                    <meta property="og:type" content={type} />
                    <meta property="og:title" content={seo.title} />
                    <meta property="og:description" content={seo.description} />
                    <meta property="og:url" content={seo.url} />
                    <meta property="og:image" content={seo.image} />
                    {/* Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content={twitterUsername} />
                    <meta name="twitter:title" content={seo.title} />
                    {/* Favicons */}
                    {[16, 32].map(size => {
                        const dimensions = `${size}x${size}`;
                        return (
                            <link
                                key={`favicon-${size}`}
                                rel="icon"
                                type="image/png"
                                sizes={dimensions}
                                href={`${iconDir}/favicon-${dimensions}.png`}
                            />
                        );
                    })}
                    {/* iOS & Safari icons */}
                    <link
                        rel="apple-touch-icon"
                        sizes="300x300"
                        href={`${iconDir}/apple-touch-icon.png`}
                    />
                    <link
                        rel="mask-icon"
                        href={`${iconDir}/safari-pinned-tab.svg`}
                        color={brandColor}
                    />
                    {/* Other tags */}
                    {children}
                </Helmet>
            );
        }}
    />
);

export default SEO;

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    pathname: PropTypes.string,
    type: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
};

SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    pathname: '',
    type: 'website',
    tags: null
};
