/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';

export const HTMLContent = ({ content, ...props }) => (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: content }} {...props} />
);

const Content = ({ content, ...props }) => <div {...props}>{content}</div>;

Content.propTypes = {
    content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

Content.defaultProps = {
    content: null
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
